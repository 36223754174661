ul{
    list-style-type:none;
    padding-left:0.5em;
    padding-right:0.5em;
    margin-left:0.5em;
    margin-right:0.5em;
}



.project-description-text{
    color:var(--main-text-color);
}


.project-card{
    margin-top:2em;
    margin-bottom:2em;
    padding-top:2em;
    padding-bottom:2em;
    border-radius: 0.25em;
}



.project-image{
    max-width: 100%;
    max-height:100%;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    border-radius: 0.4em;
    z-index:-1;
}

.project-image:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    transform:scale(1.01);
    cursor:pointer;
}

.project-description{
    
    height:100%;
    margin:auto;
    color: var(--main-text-color);
    position:relative;
    padding:2em;
    width:100%;
    vertical-align: middle;
    text-decoration: none;
    
    position:relative;
    z-index:2;
}


.description-col{
    display: flex;
    border-radius: 0.4em;
    align-items: center;
}
.project-tags{
    margin-top:1em;
}

.proj-tag{
    margin:0.25em;
    background-color: transparent;
    border: 1px solid var(--main-text-color);
    color: var(--main-text-color);
    padding:0.75em;
}

.project-card-mobile{
    margin-left:5%;
    max-width:90%;
}

.project-card-mobile img{
    max-width:100%;
}

.proj-title-mobile{
    color: var(--accent-color-1);
    font-size:1.5em;
    margin-bottom:0.5em;
    text-align:center;
}

.project-description-mobile{
    margin-top:1em;
    color:var(--main-text-color);
    font-size:0.8em!important;
}

.proj-title{
    margin-top:1em;
    font-size:2em;
    color: var(--accent-color-1);
    text-align: center;
}

.desc{
    height:100%;
}

.project-modal{
    background-color: var(--main-bg-color)!important;
}

.modal-content{
    background-color: var(--main-bg-color)!important;
    color: var(--main-text-color)!important;
}

.close{
    color:var(--main-text-color)!important;
    opacity: 1;
    font-size:2em;
}

.modal-image{
    text-align:center;
    width:60%;
}

.modal-img-wrapper{
    text-align: center;
}

.modal-btn-wrapper{
    vertical-align: middle;
    text-align:center;
}

.modal-btn{
    color: var(--main-text-color);
    font-size: 1.5em;
    text-align: center;
    transition: transform 0.3s;
    background-color: transparent;
    border-radius: 0.4em;
    border: 1px solid var(--accent-color-1);
    margin:0.5em;
}

.modal-btn:hover{
    color: var(--main-text-color);
    border: 1px solid var(--accent-color-1);
    transform: scale(0.9);
    background-color: transparent;
    
}

.modal-descriptiopn{
    padding:1em;
}

.modal-btn:after{
    background-color: transparent;
    border: 1px solid var(--accent-color-1);
}
.modal-btn:active{
    background-color: transparent;
    border: 1px solid var(--accent-color-1);
}
.modal-btn:active:after{
    background-color: transparent;
    border: 1px solid var(--accent-color-1);
}

.modal-ftr{
    margin-top:1%;
}