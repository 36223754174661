#about{
    background-color:  transparent;
    margin:2em;
    border-radius:0.5em;
}

img{
    vertical-align: middle;
    border-style: none;
}

.masthead-profile{
    width:10em;
}
#about-header{
    margin-top:0em;
}
.about-me-paragraph{
    color: var(--main-text-color);
    margin-bottom:3em;
    margin-left:50%;
    transform:translateX(-50%);
    text-align: left;
    width:70%;
    max-width:650px;
}

#hello{
    text-align: left;
    color:var(--accent-color-1);
    padding-top:0.5em;
    margin-left:50%;
    transform:translateX(-70%);
}

.about-stuff{
    padding-top:8%;
    position:relative;
    padding-bottom:15%;
}



.code-window-top{
    width:100%;
    color:var(--main-text-color);
    background-color: var(--secondary-bg-color);
    border-top-left-radius: 0.4em;
    border-top-right-radius: 0.4em;
    margin-bottom: 0;
    font-weight: bolder;
}

.code-line{
    color: white;
    font-size: 0.9em;
    padding-left: 1em;
}
.skills-btn{
    margin-top:2em;
    border-radius:0.4em;
    width:30%;
    margin-left:35%;
    border: 1px solid var(--accent-color-1);
}

@media(min-width:481px){
    .code-line{
        font-size: 0.8em;
    }
    .code-command{
        font-size: 0.9em;
    }

    .code-window-col{
        width:100%!important;
        padding:0;
    }
    .code-window-content{
        padding-left:0.5em!important;
    }

    .about-container{
        max-width:none;
    }
    .skills-btn{
        margin-top:2em;
        border-radius:0.4em;
        border: 1px solid var(--accent-color-1);
        margin-left: 35%;
        width:30%;
    }
}


@media (max-width: 480px) {
    .skills-btn{
        
        margin-left: 10%;
        width:80%;
    }
    #hello{
        text-align: center!important;
        font-size: x-large;
        color:var(--accent-color-1);
        padding-top:0.5em;
    }

    .about-me-paragraph{
        font-size:small!important;
    }

    .code-command{
        font-size: small!important;
    }

    .code-line{
        font-size: x-small!important;
    }

    .code-window-wrapper{
        width:110%;
        transform:translateX(-5%);
    }
    
}






.about-container{
    width:100%;
}

.code-window-top p{
    display:inline-block;
    margin-bottom:0;
}

.code-window-top .code-window-dot{
    float:right;
}

.code-window-dot{
    height: 0.75em;
    width: 0.75em;
    margin-top: 0.3em;
    margin-right:0.5em;
    background-color: #bbb;
    border-radius: 50%;
    display:inline-block;
    text-align: right;
  }

.code-window-dot:hover{
    background-color:var(--accent-color-1);
}



.code-window-content{
    background-color: black;
    padding:2em;
    padding-top: 0;
    margin-top:0;
    padding-bottom: 1em;
    border-bottom-left-radius: 0.4em;
    border-bottom-right-radius: 0.4em;
    text-align: left;
}

.code-key{
    color: var(--accent-color-1);
    font-weight: bolder;
    
}

.code-command{
    color: var(--accent-color-1);
    font-weight: bolder;
}
.typewriter {
    border-right: .2em solid var(--accent-color-1); /* The typwriter cursor */
    letter-spacing: .07em; /* Adjust as needed */
    animation: 
      blink-caret .75s step-end infinite;
}


@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: var(--accent-color-1); }
}

.code-window-wrapper{
    border-radius: 0.5em;
    background-color: black;
    transition: transform 0.3s;
    max-width: 650px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.code-window-wrapper:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transform:scale(1.01);
    cursor:pointer
}

#about-img{
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

}


#about-img:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  transform:scale(1.01);
}

.experience-btn{
    width:30%!important;
    margin-left:35%!important;
}