#tsparticles {
  --color: var(--main-text-color);
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
}

#home{
  height:100vh;
}

#name-header {
  color: var(--main-text-color);
  font-size: 5em;
  transition: font-size 0.2s;
  transition-timing-function: ease-out;
  z-index: 20;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */

  /* Rules below not implemented in browsers yet */
  -o-user-select: none;
  user-select: none;
  margin-bottom:0em;
}



#landing-tagline {
  color: var(--accent-color-1);
}

@media(max-width:480px){

  #name-header{
    font-size: 3em;
    font-weight: bolder;
    margin-bottom:0;
    padding-bottom: 0;
  }
  #landing-tagline{
    font-size: 1.5em;
    margin-top:0;
    padding-top: 0;
  }
  .landing-stuff{
    position:absolute;
    left:10%;
    right:10%;
    top:40%;
  }

}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}



@media(min-width:481px){

  #name-header:hover {
    font-size: 6em;
    cursor: pointer;
  }
  .landing-stuff{
    position:absolute;
    left:25%;
    right:25%;
    top:40%;
  }

}



.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


@keyframes bounce{
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(45deg) scale(3);
  }
	40% {
    transform: translateY(-15px) rotate(45deg) scale(3);
  }
	60% {
    transform: translateY(-8px) rotate(45deg) scale(3);
  }
}

.bounce{
  animation: bounce 2s 10;
}


.arrow {
  border: solid var(--main-text-color);
  border-width: 0px 2px 2px 0;
  display: inline-block;
  transition: transform 0.1s ease-in;
  transform: translateY(-8px) rotate(45deg) scale(3);
  padding: 4px;
}

.arrow:hover {
  
  transform: translateY(-8px) rotate(45deg) scale(4);
  cursor: pointer;
}
