#projects{
    background-color:  transparent;
    margin-top: 2em;
    margin-bottom:7em;
    border-radius:0.5em;
}


.project-card-col{
    margin-top:2em;
}