.navbar-collapse {
  position: fixed;
  width:100%;
  top: 0em;
  z-index: 999;
  display: inline-block;
}


.nav-menu{
  height:100%;
  padding-top: 1em;
  padding-bottom: 3em;
  transition:all 1s ease-out;
  background-color: var(--accent-color-1);

  backdrop-filter: blur(2em);
  -webkit-backdrop-filter: blur(2em);
  transform:translate(0, -4em);
}

.visible-true{
  display:block;
  opacity:1;
  transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

}

.visible-false{
  display:none;
  opacity:0;
  transition: display 0s 0.3s, opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

}

.navbar-small{
  height:100%; 
}

.nav-small-item{
    text-align: center;
    padding-top:1em;
    font-size: 1.5em;
    color:var(--dark)!important;
}

.hamburger-react {
  margin-right: 0;
  position: fixed !important;
  z-index: 999;
  right: 2em;
  top: 1em;
}

@media (max-width: 992px) {
  .navbar-large {
    visibility: hidden;
  }
  .navbar-small {
    visibility: visible;
  }
  .resume-btn{
    border: 1px solid var(--dark) !important;
  }
  .resume-link{
    color:var(--main-text-color)!important;
  }
  .hamburger-react {
    visibility: visible;
    opacity:1;
  transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
}

@media (min-width: 992px) {
  .navbar-large {
    top:1em;
    position:fixed;
    right:1.5em;
    visibility: visible;
  }
  .navbar-small {
    visibility: hidden;
  }
  .resume-btn{
    border: 1px solid var(--accent-color-1) !important;
  }
  .hamburger-react {
    visibility: hidden;
    opacity:0;
  transition: visibility 0s 0.3s, opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}
.nav-large-item {
  display: inline-block;
}

.resume-btn {
  background-color: transparent !important;
  color: var(--main-text-color) !important;
  transition: transform 0.2s;
}

.resume-btn:hover {
  transform: scale(1.1);
}

