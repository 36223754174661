.contact-wrapper{
    /*color: var(--main-text-color);*/
    z-index:10;
    position: fixed;
    left:0;
    bottom:2em;
    
    
}


#github-stats-card{
    visibility:hidden;
    display:none;
    position:absolute;
    bottom:1em;
    left:2em;
    opacity:0;
    transition: visibility 0s, opacity 0.5s linear;
}

#github-contact-icon:hover #github-stats-card{
    visibility: visible;
    display: inline-block;
    opacity:1;
}


#linkedin-contact-icon:hover #linkedin-stats-card{
    visibility: visible;
    display: inline-block;
    opacity:1;
}

#linkedin-stats-card{
    visibility:hidden;
    display:none;
    position:absolute;
    bottom:1em;
    left:2em;
    opacity:0;
    transition: visibility 0s, opacity 0.5s linear;
}

.contact-button-mix{
    display: inline-block;
    mix-blend-mode: difference;
}

a svg {
    width: 20px;
    height: 20px;
    fill:rgb(100, 100, 100);
    /*fill:var(--main-text-color)!important;*/
    transition:transform 0.2s ease-in-out;
    /*opacity:100%;*/
}

a svg:hover{
    transform: scale(1.3);
    cursor:pointer;
    fill:#f1394b;
}

.contact-button{
    z-index:12;
    margin-left:0.5em;
    margin-bottom:1em;
    /*opacity:100%;*/
}

.night-button-wrapper{
    z-index:10;
    position: fixed;
    left:0.5em;
    bottom:0.75em;
}

.night-button{
    fill:var(--accent-color-1);
}
